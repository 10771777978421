import React, { Component } from 'react';

import { connect } from '@symbolic/redux';
import { Text, Label, Button, Tooltip } from '@symbolic/rn-lib';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { View, TouchableOpacity } from 'react-native';
import { withRouter } from 'react-router-native';

import queryString from 'query-string';
import QuickEditPopup from './quick-edit-popup/quick-edit-popup';
import lib from '@symbolic/lib';
import _ from '@symbolic/lodash';
import K from '~/k';
import moment from 'moment';

var todayMoment = moment();
var today = moment(todayMoment.format('YYYY-MM-DD'));
var hoursPerDay = 8;
var currentHourOfWeek = (todayMoment.day() === 0 ? 4 : Math.min(todayMoment.day(), 5) - 1) * hoursPerDay;
var weeks = 20;
var days = 5;

var holidays = _.map([
  { date: '2021-10-01', hours: hoursPerDay },
  { date: '2021-11-25', hours: hoursPerDay * 2 },
  { date: '2021-12-24', hours: hoursPerDay * 6 },
  { date: '2022-01-17', hours: hoursPerDay },
  { date: '2022-05-30', hours: hoursPerDay },
  { date: '2022-07-04', hours: hoursPerDay },
  { date: '2022-09-05', hours: hoursPerDay },
  { date: '2022-11-24', hours: hoursPerDay },
  { date: '2022-11-25', hours: hoursPerDay },
  { date: '2022-12-23', hours: hoursPerDay },
  { date: '2022-12-26', hours: hoursPerDay * 5 },
  { date: '2023-01-16', hours: hoursPerDay },
  { date: '2023-05-29', hours: hoursPerDay },
  { date: '2023-07-03', hours: hoursPerDay * 2 },
  { date: '2023-09-04', hours: hoursPerDay },
  { date: '2023-10-02', hours: 3 },
  { date: '2023-11-01', hours: 3 },
  { date: '2023-11-23', hours: hoursPerDay * 2 },
  { date: '2023-12-01', hours: 3 },
  { date: '2023-12-25', hours: hoursPerDay * 5 },
  { date: '2024-01-01', hours: hoursPerDay + 3 },
  { date: '2024-01-15', hours: hoursPerDay },
  { date: '2024-02-01', hours: 3 },
  { date: '2024-03-01', hours: 3 },
  { date: '2024-04-01', hours: 3 },
  { date: '2024-05-01', hours: 3 },
  { date: '2024-06-03', hours: 3 },
  { date: '2024-07-01', hours: 3 },
  { date: '2024-08-01', hours: 3 },
  { date: '2024-09-03', hours: 3 },
  { date: '2024-10-01', hours: 3 },
  { date: '2024-11-01', hours: 3 },
  { date: '2024-12-02', hours: 3 },
  { date: '2024-05-27', hours: hoursPerDay },
  { date: '2024-07-04', hours: hoursPerDay },
  { date: '2024-09-02', hours: hoursPerDay },
  { date: '2024-11-28', hours: hoursPerDay * 2 },
  { date: '2024-12-23', hours: hoursPerDay * 3 },
  { date: '2025-01-01', hours: hoursPerDay },
  { date: '2025-01-20', hours: hoursPerDay }
], holiday => {
  let start = moment(today);
  let end = moment(holiday.date);
  let weekdayCounter = 0;
  let dir = 1;

  if (today > end) {
    dir = -1;
    start = end;
    end = moment(today);
  }

  while (start < end) {
    if (start.format('ddd') !== 'Sat' && start.format('ddd') !== 'Sun'){
      weekdayCounter += dir;
    }

    start = moment(start).add(1, 'days');
  }

  var startHour = currentHourOfWeek + weekdayCounter * hoursPerDay;
  var endHour = startHour + holiday.hours;

  return { ...holiday, startHour, endHour };
});

var currentHoliday = _.find(holidays, holiday => holiday.startHour <= currentHourOfWeek && holiday.endHour > currentHourOfWeek);

if (currentHoliday) {
  currentHourOfWeek += currentHoliday.endHour - currentHourOfWeek;
}

function Week(props) {
  var {w, processInstancesData, processSteps} = props;
  var {isPrinting} = props.activeView.data;

  var columnWidth = 150;
  var firstColumnWidth = 150;
  var cellWidth = columnWidth / hoursPerDay;
  var cellHeight = 18;
  var rowHeight = cellHeight + K.spacing * 3;
  var cellsDataByProcessStep = [];
  var visibleProcessInstances = [];

  var getWorkPeriodOffset = ({startHour}) => {
    return _.sum(_.map(_.filter(holidays, holiday => holiday.startHour > currentHourOfWeek && holiday.startHour < startHour), 'hours'));
  };

  var workPeriods = [];
  var weekStartHour = w * days * hoursPerDay;
  var weekEndHour = weekStartHour + days * hoursPerDay;
  var startHour = weekStartHour + _.get(_.find(holidays, {startHour: weekStartHour}), 'hours', 0); //if holiday on beginning of week, add
  var holidayOffset = getWorkPeriodOffset({startHour: weekStartHour});
  var endHour = weekEndHour - _.get(_.find(holidays, {endHour: weekEndHour}), 'hours', 0); //if holday at end of week, subtract
  var workPeriod = {startHour, holidayOffset: getWorkPeriodOffset({startHour})};

  _.forEach(holidays, holiday => {
    if (holiday.startHour < endHour && holiday.endHour > startHour) {
      workPeriod.endHour = holiday.startHour;

      workPeriods.push(workPeriod);

      var holidayOffset = getWorkPeriodOffset({startHour: holiday.endHour});

      workPeriod = {startHour: holiday.endHour, holidayOffset};
    }
  });

  workPeriod.endHour = endHour;

  workPeriods.push(workPeriod);

  _.forEach(processSteps, (_processStep, processStepIndex) => {
    var cellsData = [];
    var top = 0;

    cellsDataByProcessStep.push(cellsData);

    _.forEach(workPeriods, (workPeriod, wi) => {
      _.forEach(processInstancesData, ({processInstance, processStepsData}, index) => {
        var processStepData = processStepsData[processStepIndex];

        var {startHour, endHour} = processStepData;
        var overflowsIntoNextWorkPeriod = false, overflowsIntoLastWorkPeriod = false;

        var min = workPeriod.startHour - workPeriod.holidayOffset;
        var max = workPeriod.endHour - workPeriod.holidayOffset;

        if (startHour < min) {
          overflowsIntoLastWorkPeriod = true;

          // var delta = Math.max(min, startHour) - startHour;

          startHour = Math.max(min, startHour);

          // endHour += delta;
        }
        if (endHour > max) {
          overflowsIntoNextWorkPeriod = true;
          endHour = Math.min(max, endHour);
        }

        var left = (startHour - (weekStartHour - workPeriod.holidayOffset)) * cellWidth;
        var cellCount = endHour - startHour;
        if (processInstance.id === 6173 && processStepData.processStep.id === 1018) console.log(startHour, endHour, cellCount);

        if (index > 0 && processInstancesData[index - 1].processStepsData[processStepIndex].endHour > startHour) {
          top = top === 0 ? 1 : 0;
        }
        else {
          top = 0;
        }

        if (cellCount > 0 && !_.includes(visibleProcessInstances, processInstance)) {
          visibleProcessInstances.push(processInstance);
        }

        _.times(cellCount, (i) => {
          cellsData.push({
            processInstance, processStepData, startHour, endHour, top, isFirstInSet: i === 0,
            left: left + i * cellWidth,
            isFirst: overflowsIntoLastWorkPeriod ? 0 : i === 0,
            isLast: overflowsIntoNextWorkPeriod ? 0 : i === cellCount - 1
          });
        });
      });
    });
  });

  return (<>
    <View dataSet={{ report: 1 }} style={{ backgroundColor: isPrinting ? 'transparent' : K.colors.gray, borderRadius: K.borderRadius, padding: K.spacing, paddingHorizontal: K.spacing * 3, marginBottom: K.spacing * 2 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', height: rowHeight }}>
        <View style={{ width: firstColumnWidth }}></View>
        {_.times(days, d => (
          <View key={d} style={{ width: columnWidth, alignItems: 'center' }}>
            <Label>{moment(todayMoment.startOf('isoweek')).add(d + w * 7, 'days').format('ddd D')}</Label>
          </View>
        ))}
      </View>
      <View style={{ position: 'relative' }}>
        {_.map(processSteps, (processStep, processStepIndex) => {
          return (
            <View key={processStep.id} style={{ zIndex: 1, flexDirection: 'row', alignItems: 'center', height: rowHeight }}>
              <View style={{ width: firstColumnWidth }}>
                <Text numberOfLines={3}>{processStep.title}</Text>
              </View>
              <View style={{ position: 'relative', alignSelf: 'stretch' }}>
                {_.map(cellsDataByProcessStep[processStepIndex], ({ processStepData, startHour, endHour, processInstance, isFirstInSet, isFirst, isLast, left, top }) => (
                  <View key={`${left}-${top}-${processInstance.id}`} style={{
                    position: 'absolute',
                    left: left + (isFirst ? 1 : 0),
                    top: (rowHeight - cellHeight) / 2 + (top * cellHeight),
                    width: cellWidth - (isFirst ? 1 : 0),
                    height: cellHeight - 1,
                    borderLeftColor: 'rgba(0, 0, 0, 0.15)',
                    borderLeftWidth: isFirst ? 0 : 1,
                    backgroundColor: lib.colors.colorFor({ id: processInstance.id * 2 }),
                    zIndex: isFirstInSet ? 1 : 0,
                    ...(isFirst ? { borderTopLeftRadius: 100, borderBottomLeftRadius: 100 } : {}),
                    ...(isLast ? { borderTopRightRadius: 100, borderBottomRightRadius: 100 } : {}),
                  }}>
                    {isFirstInSet && (
                      <Tooltip text={processInstance.title}>
                        <TouchableOpacity
                          onPress={() => props.setState({ selectionData: { processInstanceId: processInstance.id, processStepId: processStepData.processStep.id } })}
                          style={{ paddingHorizontal: cellWidth / 2, top: 1, width: (endHour - startHour) * cellWidth }}
                        >
                          <Text numberOfLines={1}>{processInstance.title}</Text>
                        </TouchableOpacity>
                      </Tooltip>
                    )}
                  </View>
                ))}
              </View>
            </View>
          );
        })}
        {_.times(days + 1, day => (
          <View key={day} style={{ zIndex: -1, borderLeftWidth: 1, opacity: 0.2, left: firstColumnWidth + day * columnWidth, height: '100%', top: 0, position: 'absolute' }} />
        ))}
      </View>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: columnWidth * 6, marginTop: K.spacing * 3, marginBottom: K.spacing }}>
        {_.map(visibleProcessInstances, processInstance => {
          var productionProjectFieldId = 1;
          var projectValue = processInstance.fields[productionProjectFieldId] || 0;
          var projectValueString = !!projectValue && ` - ${projectValue / 100000}K`;

          return (
            <View key={processInstance.id} style={{ marginRight: K.margin, marginBottom: K.margin, backgroundColor: lib.colors.colorFor({ id: processInstance.id * 2 }), height: cellHeight, alignItems: 'center', justifyContent: 'center', paddingHorizontal: K.spacing, borderRadius: 100 }}>
              <Text>{processInstance.title}{projectValueString}</Text>
            </View>
          )
        })}
      </View>
    </View>
    <View></View>
  </>
  );
}

class ReportPage extends Component {
  state = {
    selectionData: null,
    week: 'this'
  };

  getSizeForProcessInstance = (processInstance, processStep) => {
    var stepSize = _.get(processInstance.steps, `${processStep.id}.size`);

    if (stepSize) return stepSize;

    var productionProjectValueFieldId = 1;
    var productionProjectBrandFieldId = 110;
    var productionProjectDifficultyFieldId = 111;

    var processInstanceValue = _.get(processInstance.fields, productionProjectValueFieldId, 0);
    var processInstanceBrand = _.get(processInstance.fields, productionProjectBrandFieldId);
    var processInstanceDifficultyFactor = _.get(processInstance.fields, productionProjectDifficultyFieldId, 1);

    var isHB = processInstanceBrand !== "2"; // HB = "3", ST = "2"
    var denominator = isHB ? 17.5 : 11.37;

    var dollarAmountInTenThousands = processInstanceValue / 100000;
    var difficultyFactor = parseFloat(processInstanceDifficultyFactor);
    var multiplier = isNaN(difficultyFactor) ? 1 : difficultyFactor;

    return Math.ceil((dollarAmountInTenThousands / denominator) * multiplier);
  }


  render() {
    var {props} = this;
    var {processInstances} = props;
    var processInstancesData = [];
    var processSteps = _.filter(_.sortBy(props.processSteps, 'rank'), ({ id }) => _.includes([1017, 1018, 1019, 1020, 1021], id));

    _.forEach(processInstances, processInstance => {
      var processStepsData = [];

      _.forEach(processSteps, (processStep, processStepIndex) => {
        var minStartHour = currentHourOfWeek;
        var processStepOverrides = _.get(processInstance.steps, `${processStep.id}.processStepOverrides`);

        var size = this.getSizeForProcessInstance(processInstance, processStep);
        var requiredCompletionRatio = _.get(processStepOverrides, `previousStepPercentCompleteRequiredToStart`, _.get(processStep, `previousStepPercentCompleteRequiredToStart`, 1));

        var processInstanceHours = Math.ceil(size * (1 - _.get(processInstance.steps, `${processStep.id}.percentComplete`, 0)));
        if (processInstance.id === 6173 && processStep.id === 1018) console.log(processInstanceHours, size);
        if (processInstancesData.length) {
          //same step, previous process instance
          var lastProcessInstanceData = _.last(processInstancesData);
          var lastProcessStepData = lastProcessInstanceData.processStepsData[processStepIndex];
          var lastProcessStepDuration = lastProcessStepData.endHour - lastProcessStepData.startHour;
          var lastProcessInstanceProcessStepOverrides = _.get(lastProcessInstanceData.processInstance.steps, `${processStep.id}.processStepOverrides`);

          var afterCompleteBufferSize = _.get(lastProcessInstanceProcessStepOverrides, `afterCompleteBufferSize`);

          if (afterCompleteBufferSize === undefined) {
            afterCompleteBufferSize = _.get(lastProcessStepData.processStep, `afterCompleteBufferSize`, 0);

            if (afterCompleteBufferSize !== 0 && Math.abs(afterCompleteBufferSize) < 1 && lastProcessStepData) {
              afterCompleteBufferSize = (lastProcessStepData.processInstanceHours || 0) * afterCompleteBufferSize;
            }
          }

          var buffer = Math.ceil(afterCompleteBufferSize);

          minStartHour = lastProcessStepData.endHour + (lastProcessStepDuration ? buffer : 0);
        }

        if (processStepIndex === 0) {
          var startHour = minStartHour;
        }
        else {
          //previous step in process, same instance
          var previousProcessStepData = _.last(processStepsData);
          var previousProcessStepDuration = previousProcessStepData.endHour - previousProcessStepData.startHour;
          var startHour = Math.max(minStartHour, previousProcessStepData.startHour + (previousProcessStepDuration ? Math.ceil(previousProcessStepDuration * requiredCompletionRatio) : 0));
        }

        var endHour = startHour;

        if (processInstanceHours && !_.includes(['complete', 'notApplicable'], _.get(processInstance, `steps.${processStep.id}.status`))) {
          endHour = startHour + processInstanceHours;
        }

        processStepsData.push({processStep, startHour, endHour, processInstanceHours});
      });

      processInstancesData.push({processInstance, processStepsData});
    });

    return (
      <View style={{display: 'block'}}>
        {_.times(weeks, w => (
          <Week {...this.props} {...{w, processInstancesData, processSteps}} {...this.state} setState={(...args) => this.setState(...args)} />
        ))}
        {this.state.selectionData && (
          <QuickEditPopup {...this.state.selectionData} onClose={() => this.setState({ selectionData: null })} />
        )}
      </View>
    )
  }
}

export default withRouter(withSafeAreaInsets(connect({
  mapState: state => {
    var props = {
      ..._.pick(state, ['activeView'])
      // report: state.resources.reports.byId[1] //TODO
    };

    //TODO 404
    if (true) {
      var processTypeId = 187; //TODO

      props.processType = state.resources.processTypes.byId[processTypeId];
      props.processSteps = _.filter(state.resources.processSteps.byId, { processTypeId });
      props.processInstances = _.chain(state.resources.processInstances.byId)
        .filter({ processTypeId })
        .filter({ isArchived: 0, isComplete: 0 })
        .sortBy([
          processInstance => {
            var byWhen = _.get(processInstance, `steps.1017.byWhen`);

            return byWhen ? moment(byWhen).unix() : moment('2050-01-01').unix();
          },
          'rank',
          'id'
        ])
        .value();
    }

    return props;
  },
  mapDispatch: {

  }
})(ReportPage)));
